<template>
  <v-card class="my-1">
    <v-card-title>
      <h5>Situação das Solicitações</h5>
    </v-card-title>
    <v-card-text>
      <highcharts 
        :options=chartOptions 
        :deepCopyOnUpdate="false" 
      />
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  name: "DashboardServicosVisaoAneelCardSituacaoSolicitacoes",
  props: {
    dados: {
      type: Object,
      required: true,
    },
    exportingOptions : {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    chartOptions: {},
  }),
  mounted() {
    this.setChartOptions();
  },
  methods: {
    setChartOptions() {
      this.chartOptions = {
        chart: {
          type: 'pie',
          height: 250,
        },
        // colors: [],
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              distance: 30,
              formatter: function() { 
                return `${this.point.name}: ${parseFloat(this.point.y).toLocaleString('pt-BR')}` 
                // return parseFloat(this.point.y).toLocaleString('pt-BR')
              }
            },
            showInLegend: true
          }
        },
        credits: { enabled: false },
        title: { text: null },
        subtitle: { text: null },
        legend: { enabled: false },
        lang: {
          noData: "Sem dados para exibição"
        },
        series: {
          name: "Valor",
          colorByPoint: true,
          innerSize: '75%',
          animation: false,
          data: [
            { name: "Serviços Suspensos", y: this.dados.servicos_suspensos },
            { name: "Serviços Pendentes Fora Prazo", y: this.dados.servicos_pendentes_fora_prazo },
            { name: "Serviços Pendentes no Prazo", y: this.dados.servicos_pendentes_no_prazo }
          ],
        },
        exporting: this.exportingOptions,
      }; 
    },
  },
  watch: {
    dados() {
      this.setChartOptions();
    }
  },
}
</script>

<style></style>